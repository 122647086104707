import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
// import useScript from '../../hooks/useScript';

const useScript = (src)=> {

	useEffect(() => {
		const tag = document.createElement('script');
		tag.async = true;
		tag.src = src;
		document.body.appendChild(tag);
		
		return () => {
		  document.body.removeChild(tag);
		}
		}, [src])

   

	}
const Header = () => {

	

useScript('/assets/js/jquery.min.js')
useScript('/assets/js/slick.js')
useScript('/assets/js/jquery.counterup.min.js')
useScript('/assets/js/bootstrap.min.js')
useScript('/assets/js/popper.min.js')
useScript('/assets/js/jquery.magnific-popup.min.js')
useScript('/assets/js/appear.js')
useScript('/assets/js/waypoints.min.js')
useScript('/assets/js/isotope.pkgd.min.js')
useScript('/assets/js/rbtools.min.js')
useScript('/assets/js/rs6.min.js')
useScript('/assets/js/twenty.js')
useScript('/assets/js/imagesloaded.pkgd.min.js')
useScript('/assets/js/masonry.pkgd.min.js')
useScript('/assets/js/wow.min.js')
useScript('/assets/js/jquery.inputarrow.js')
useScript('/assets/js/script.js')

	return (
		<>
			<header id="archx-header" className="archx-header-section archx-header-style-two">
				<div className="header-main-menu-wrapper d-flex justify-content-between align-items-center position-relative">
					<div className="archx-side-bar-menu-logo d-flex">
						<div className="brand-logo">
							<a href="#"><img src="/assets/img/logo/logo.png" alt="" style={{height: "68px"}}/></a>
						</div>
					</div>
					<div className="header-cta-btn-wrapper d-flex align-items-center">
						<div className="archx-main-navigation-wrap">
							<nav className="main-navigation clearfix ul-li">
								<ul id="main-nav" className="nav navbar-nav clearfix">
									
									<li><Link to="/">Home</Link></li>

									<li><Link to="/services">Services</Link></li>

									<li><Link to="/about">About Us</Link></li>
									<li><Link to="/contact">Contact Us</Link></li>
								</ul>
							</nav>
						</div>
						<div className="header-cta-number d-flex align-items-center text-uppercase">
							<div className="header-cta-icon d-flex justify-content-center align-items-center">
								<i className="fas fa-phone-alt"></i>
							</div>
							<div className="header-cta-value">
								<span className="ar-title">Phone:</span>
								<span className="ar-value">88 1900 6789 56</span>
							</div>
						</div>
						<div className="header-cta-btn">
						<Link to="/contact"><a className="d-flex justify-content-center align-items-center" >  Get Quate Now  <i className="fal fa-long-arrow-right"></i></a> </Link>
						</div>
					</div>
					<div className="mobile_menu">
						<div className="mobile_menu_button open_mobile_menu">
							<i className="fal fa-bars"></i>
						</div>
						<div className="mobile_menu_wrap">
							<div className="mobile_menu_overlay open_mobile_menu"></div>
							<div className="mobile_menu_content">
								<div className="mobile_menu_close open_mobile_menu">
									<i className="fal fa-times"></i>
								</div>
								<div className="m-brand-logo">
									<a href="!#"><img src="/assets/img/logo/logo-5.png" alt="" /></a>
								</div>
								<nav className="mobile-main-navigation  clearfix ul-li">
									<ul id="m-main-nav" className="nav navbar-nav clearfix">
										<li><Link to="/">Home</Link></li>

										<li><a href="about.html">About</a></li>
										<li className="dropdown">
											<a href="!#">Project</a>
											<ul className="dropdown-menu clearfix">
												<li><a href="project.html">Project One</a></li>
												<li><a href="project-2.html">Project Two</a></li>
												<li><a href="project-single.html">Project Details</a></li>
											</ul>
										</li>
										<li className="dropdown">
											<a href="!#">Pages</a>
											<ul className="dropdown-menu clearfix">
												<li><a href="service.html">Services</a></li>
												<li className="dropdown">
													<a href="service-single.html">Services Details</a>
													<ul className="dropdown-menu clearfix">
														<li><a href="service-single.html">Construction Building</a></li>
														<li><a href="service-single-2.html">Architect Design</a></li>
														<li><a href="service-single-3.html">Interior Design</a></li>
														<li><a href="service-single-4.html">3D Animation</a></li>
														<li><a href="service-single-5.html">House Renovation</a></li>
														<li><a href="service-single-6.html">Ideas & Inspiration</a></li>
													</ul>
												</li>
												<li><a href="team.html">Team Page</a></li>
												<li><a href="team-single.html">Team DetailsS</a></li>
												<li><a href="faq.html">FAQ Page</a></li>
												<li><a href="pricing.html">Pricing Page</a></li>
												<li><a href="shop.html">Shop Page </a></li>
												<li><a href="shop-single.html">Shop Details</a></li>
											</ul>
										</li>
										<li className="dropdown">
											<a href="!#">Blog</a>
											<ul className="dropdown-menu clearfix">
												<li><a href="blog.html">Blog One</a></li>
												<li><a href="blog-2.html">Blog Two</a></li>
												<li><a href="blog-single.html">Blog Details</a></li>
											</ul>
										</li>
										<li><a href="contact.html">Contact</a></li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</header>
			<div className="xs-sidebar-group info-group">
				<div className="xs-overlay xs-bg-black">
					<div className="row loader-area">
						<div className="col-3 preloader-wrap">
							<div className="loader-bg"></div>
						</div>
						<div className="col-3 preloader-wrap">
							<div className="loader-bg"></div>
						</div>
						<div className="col-3 preloader-wrap">
							<div className="loader-bg"></div>
						</div>
						<div className="col-3 preloader-wrap">
							<div className="loader-bg"></div>
						</div>
					</div>
				</div>
				<div className="xs-sidebar-widget">
					<div className="sidebar-widget-container">
						<div className="widget-heading">
							<a href="#" className="close-side-widget">
								X
							</a>
						</div>
						<div className="sidebar-textwidget">
							<div className="sidebar-info-contents headline pera-content">
								<div className="content-inner">
									<div className="logo">
										<a href="index.html"><img src="/assets/img/logo/logo-3.png" alt="" /></a>
									</div>
									<div className="content-box">
										<h5>About Us</h5>
										<p className="text">The argument in favor of using filler text goes something like this: If you use real content in the Consulting Process, anytime you reach a review point you’ll end up reviewing and negotiating the content itself and not the design.</p>
									</div>
									<div className="gallery-box ul-li">
										<h5>Gallery</h5>
										<ul>
											<li>
												<a href="#"><img src="/assets/img/gallery/01.png" alt="" /></a>
											</li>
											<li>
												<a href="#"><img src="/assets/img/gallery/02.png" alt="" /></a>
											</li>
											<li>
												<a href="#"><img src="/assets/img/gallery/03.png" alt="" /></a>
											</li>
											<li>
												<a href="#"><img src="/assets/img/gallery/04.png" alt="" /></a>
											</li>
											<li>
												<a href="#"><img src="/assets/img/gallery/05.png" alt="" /></a>
											</li>
											<li>
												<a href="#"><img src="/assets/img/gallery/06.png" alt="" /></a>
											</li>
										</ul>
									</div>
									{/* <div className="content-box">
										<h5>Social Account</h5>
										<ul className="social-box">
											<li><a href="https://www.facebook.com/" className="fab fa-facebook-f"></a></li>
											<li><a href="https://www.twitter.com/" className="fab fa-twitter"></a></li>
											<li><a href="https://dribbble.com/" className="fab fa-dribbble"></a></li>
											<li><a href="https://www.linkedin.com/" className="fab fa-linkedin"></a></li>
										</ul>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Header
import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
        <footer id="archx-footer" className="archx-footer-section position-relative" data-background="assets/img/bg/ar-ft-bg.png">
		<span className="archx-footer-mail position-absolute">Contact@gmail.com</span>
		<span className="archx-footer-address  position-absolute">Level 13, 2 <b>Elizabeth St</b>, Melbourne</span>
		<div className="container">
			<div className="archx-newslatter-content-area d-flex justify-content-between align-items-center">
				<div className="archx-newslatter-text headline-2 pera-content">
					<h3>We’d like to <span>Talk</span></h3>
					<p>See Everything About Your Users At One Place</p>
				</div>
				<div className="archx-newslatter-form position-relative">
					<form action="#" method="get">
						<input type="email" name="email" placeholder="Enter Email Address"/>
						<button type="submit">Subscribed Us <i className="fal fa-long-arrow-right"></i></button>
					</form>
				</div>
			</div>
			<div className="archx-footer-content">
				<div className="row">
					<div className="col-lg-3 col-md-6">
						<div className="archx-footer-widget headline-2">
							<div className="logo-widget">
								<div className="brand-logo">
									<a href="#"><img src="assets/img/logo/logo.png" style={{ width:"200px", height:"auto"}}alt=""/></a>
								</div>
								{/* <div className="logo-text">
									The world’s first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy
								</div> */}
								<div className="logo-cta-info ul-li-block">
									<ul>
										<li><i className="fal fa-map-marker-alt"></i> E 3/2 , Pricilla Apartments, Dias and Pereira Nagar, Vijay Park, Naigaon - West, Palghar district 401207- Maharashtra, India.</li>
										<li><i className="fas fa-phone-alt"></i>  (+91) 98201 56161</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="col-lg-3 col-md-6">
						<div className="archx-footer-widget headline-2">
							<div className="menu-widget ul-li-block">
								<h3 className="widget-title">Marketplace</h3>
								<ul>
									<li><a href="about.html">About Us</a></li>
									<li><a href="blog.html">Recent News</a></li>
									<li><a href="contact.html">Contact Us</a></li>
									<li><a href="service-single.html">Interior Design</a></li>
									<li><a href="project.html">Recent Project</a></li>
									<li><a href="service.html">Services</a></li>
								</ul>
							</div>
						</div>
					</div> */}
					<div className="col-lg-3 col-md-6">
						<div className="archx-footer-widget headline-2">
							<div className="menu-widget ul-li-block">
								<h3 className="widget-title">Useful Links</h3>
								<ul>
								<Link to="/about"><li><a href="about.html">About Us</a></li></Link>	
									{/* <li><a href="blog.html">Recent News</a></li> */}
									<Link to="/contact"><li><a href="contact.html">Contact Us</a></li> </Link>
									{/* <li><a href="service-single.html">Interior Design</a></li> */}
									{/* <li><a href="project.html">Recent Project</a></li> */}
									<Link to="/services"><li><a href="service.html">Services</a></li> </Link>
								</ul>
							</div>
						</div>
					</div>
					{/* <div className="col-lg-3 col-md-6">
						<div className="archx-footer-widget headline-2">
							<div className="award-widget">
								<h3 className="widget-title">Our Information</h3>
								<div className="total-award d-flex align-items-center">
									<span className="aw-title">Total Awards</span>
									<span className="aw-number">X8</span>
								</div>
								<div className="aw-instagram-wrap ul-li">
									<ul>
										<li><a href="#"><img src="assets/img/gallery/ins1.jpg" alt=""/> <i className="fab fa-instagram"></i></a></li>
										<li><a href="#"><img src="assets/img/gallery/ins2.jpg" alt=""/> <i className="fab fa-instagram"></i></a></li>
									</ul>
								</div>
								<div className="aw-btn-wrap position-relative">
									<img src="assets/icon/fr1.png" alt=""/>We provide fast on-demand <span>printing.</span>
									<span className="aw-line position-absolute"><img src="assets/icon/line1.png" alt=""/></span>
								</div>
							</div>
						</div>
					</div>  */}
				</div>
			</div>
			<div className="archx-footer-copyright d-flex justify-content-between align-items-center">
				<div className="archx-footer-copyright-text">
					Copyright © <a href="#"> 2023 </a> by WUDPULSE  All Rights Reserved. 
					Designed © Developed by V-Art Services Private Limited
				</div>
				{/* <div className="archx-footer-copyright-social ul-li">
					<ul>
						<li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
						<li><a href="#"><i className="fab fa-twitter"></i></a></li>
						<li><a href="#"><i className="fab fa-behance"></i></a></li>
						<li><a href="#"><i className="fab fa-youtube"></i></a></li>
					</ul>
				</div> */}
			</div>
		</div>
	</footer>
    </>
  )
}

export default Footer
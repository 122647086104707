import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

function Service() {
	const serviceId = useParams();
	console.log(serviceId)

	useEffect(() => {
		window.scrollTo(0,0)
	  },[])

	return (

		<>
			{/* Start of Breadcrumb section */}
			<section id="arck-breadcrumb" className="arck-breadcrumb-section-2 position-relative" data-background="assets/img/bg/ar-shape.png">
				<div className="slider-side-content position-absolute">
					<span className="archx-slider-side1 position-absolute"><a href="#">Contact@gmail.com</a></span>
				</div>
				<div className="container">
					<div className="arck-breadcrumb-content position-relative text-center headline-2 ul-li">
						<h1>Project Details</h1>
						<ul>
							<li><a href="#">Home</a></li>
							<li>Project Details</li>
						</ul>
					</div>
				</div>
			</section>
			{/* End of Breadcrumb section */}

			{/* Start of Project Details section */}
			<section id="arck-project-details" class="arck-project-details-section inner-page-padding">
				<div class="container">
					<div class="arck-project-details-img-slider">
						<Swiper slidesPerView={1}
							spaceBetween={30}
							pagination={{
							  clickable: true,
							}}
							autoplay={{delay: 5000}}
							modules={[Pagination, Autoplay]}
							className="arck-project-details-img-slider"
						>
							<div class="arck-project-details-slider">
								<SwiperSlide>

									<div class="arck-project-slide-item">
										<img src="/assets/img/project/prd1.jpg" alt="" />
									</div>
								</SwiperSlide>
								<SwiperSlide>

									<div class="arck-project-slide-item">
										<img src="/assets/img/project/prd1.jpg" alt="" />
									</div>
								</SwiperSlide>
								<SwiperSlide>

									<div class="arck-project-slide-item">
										<img src="/assets/img/project/prd1.jpg" alt="" />
									</div>
								</SwiperSlide>
							</div>
						</Swiper>
					</div>
					<div class="arck-project-details-text-wrapper">
						<div class="row">
							<div class="col-lg-8">
								<div class="arck-project-details-text headline pera-content ul-li-block">
									<h3>Project Details</h3>
									<p>Neque porro quisquam est, qui is dolor emr ipsum quia dolor sit amet  the consec tetur is adipisci velit, sed the quia non num quam. Neque porro quis quam est, qui dolor emr ipsum quia dolor sit amet, consec tetur. Esse mo lestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et ac cumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue </p>
									<ul>
										<li>Planning solution,10 construction drawings, selection materials</li>
										<li>Developers and technical architects, selection of materials</li>
										<li>Digital project planning resourcing selection of materials</li>
									</ul>
									<p>Neque porro quisquam est, qui is dolor emr ipsum quia dolor sit amet  the consec tetur is adipisci velit, sed the quia non num quam. Neque porro quis quam est, qui dolor emr ipsum quia dolor sit amet   </p>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="arck-project-details-feature headline">
									<h3>Project Info</h3>
									<div class="feature-list ul-li-block">
										<ul>
											<li>Clients: <span>Francisco Hanson</span></li>
											<li>Location: <span>Italy, Andaman</span></li>
											<li>Completion: <span>February 12, 2022</span></li>
											<li>Project Type:  <span>Interior Design</span></li>
											<li>Architects: <span>Jonathan Hunt</span></li>
										</ul>
									</div>
									<div class="project-share d-flex justify-content-between">
										<span class="title">Share</span>
										<div class="share-social">
											<a href="#"><i class="fab fa-facebook-f"></i></a>
											<a href="#"><i class="fab fa-twitter"></i></a>
											<a href="#"><i class="fab fa-behance"></i></a>
											<a href="#"><i class="fab fa-youtube"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="arck-project-details-img-wrapper">
						<div class="row">
							<div class="col-md-4">
								<div class="arck-project-details-img-item">
									<img src="/assets/img/project/prd2.jpg" alt="" />
								</div>
							</div>
							<div class="col-md-4">
								<div class="arck-project-details-img-item">
									<img src="/assets/img/project/prd3.jpg" alt="" />
								</div>
							</div>
							<div class="col-md-4">
								<div class="arck-project-details-img-item">
									<img src="/assets/img/project/prd4.jpg" alt="" />
								</div>
							</div>
						</div>
					</div>
					<div class="arck-project-details-text headline pera-content ul-li-block">
						<h4>Design & Construction</h4>
						<p>Neque porro quisquam est, qui is dolor emr ipsum quia dolor sit amet  the consec tetur is adipisci velit, sed the quia non num quam. Neque porro quis quam est, qui dolor emr ipsum quia dolor sit amet, consec tetur. Esse mo lestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et ac cumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>

						<p>Neque porro quisquam est, qui is dolor emr ipsum quia dolor sit amet  the consec tetur is adipisci velit, sed the quia non num quam. Neque porro quis quam est, qui dolor emr ipsum quia dolor sit amet, consec tetur. Esse mo lestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et ac cumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. </p>
					</div>
					<div class="arck-project-details-img-text">
						<div class="row">
							<div class="col-lg-6">
								<div class="arck-project-details-img-area">
									<img src="/assets/img/project/prd5.jpg" alt="" />
								</div>
							</div>
							<div class="col-lg-6">
								<div class="arck-project-details-text headline pera-content ul-li-block">
									<h4>Result & Benefits</h4>
									<p>Neque porro quisquam est, qui is dolor emr ipsum quia dolor sit amet  the consec tetur is adipisci velit, sed the quia non num quam. Neque porro quis quam est, qui dolor emr ipsum quia dolor sit amet, consec tetur. Esse mo lestie Benefits eugiat nulla facilisis</p>
									<ul>
										<li>Planning solution,10 construction drawings, selection materials</li>
										<li>Developers and technical architects, selection of materials</li>
										<li>Digital project planning resourcing selection of materials</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="arck-project-next-prev-btn d-flex text-uppercase justify-content-between">
						<a href="#"><i class="far fa-long-arrow-left"></i> Previous Project</a>
						<a href="#"> Next Project <i class="far fa-long-arrow-right"></i></a>
					</div>
				</div>
			</section>
			{/* End of Project Details section */}
		</>
	)
}

export default Service
import React, { useEffect } from 'react'

function About() {

	useEffect(() => {
	  window.scrollTo(0,0);
	}, [])
	

  return (
    <>
    {/* Start Of Breadcrumb Section */}
    <section id="arck-breadcrumb" className="arck-breadcrumb-section-2 position-relative" data-background="assets/img/bg/ar-shape.png">
		<div className="slider-side-content position-absolute">
			<span className="archx-slider-side1 position-absolute"><a href="#">Contact@gmail.com</a></span>
		</div>
		<div className="container">
			<div className="arck-breadcrumb-content position-relative text-center headline-2 ul-li">
				<h1>About Us</h1>
				<ul>
					<li><a href="#">Home</a></li>
					<li>About Us</li>
				</ul>
			</div>
		</div>
	</section>
    {/* End Of Breadcrumb Section */}

    {/* Start of Who We Are */}
    <section id="about-page-about" className="about-page-about-section">
		<div className="container">
			<div className="about-page-about-content">
				<div className="row">
					<div className="col-lg-6">
						<div className="arck-about-img-wrapper position-relative">
							<div className="acrk-img-shape1 position-absolute"><i></i></div>
							<div className="acrk-img-shape2 position-absolute"><i></i></div>
							<span className="shape1 position-absolute"><img src="assets/img/bg/dot-shape.png" alt=""/></span>
							<div className="inner-img">
								<img src="assets/img/about/furniture1.jpg" alt=""/>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="arck-about-text-wrap-2">
							<div className="arck-section-title headline pera-content">
								<span className="sub-title text-uppercase">About Archix</span>
								<h2>Who We <span>Are .</span></h2>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting indust. Lorem Ipsum has been the dard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to a type speci  men book. It has survived not turies</p>
							</div>
							<div className="about-signature-img d-flex wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
								{/* <div className="inner-img">
									<img src="assets/img/about/abs1.jpg" alt=""/>
								</div> */}
								{/* <div className="inner-text headline  position-relative">
									<h3>‘’Information architecture helps organize
										content overall so it’s easy to find and
									Imagine a storeroom filled use’’ </h3>
									<div className="about-author">
										<h4>Mrowni Rosen</h4>
										<span>CEO & Founder</span>
									</div>
									<span className="sign-img position-absolute"><img src="assets/img/about/sign.png" alt=""/></span>
								</div> */}
							</div>
							{/* <div className="arck-btn">
								<a className="d-flex justify-content-center align-items-center text-uppercase" href="project.html">View Works</a>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    {/* End of Who we are */}


    {/* Start of Who We Do */}
    <section id="about-page-about" className="about-page-about-section">
		<div className="container">
			<div className="about-page-about-content">
				<div className="row flex-row-reverse">
					<div className="col-lg-6">
						<div className="arck-about-img-wrapper position-relative">
							<div className="acrk-img-shape1 position-absolute"><i></i></div>
							<div className="acrk-img-shape2 position-absolute"><i></i></div>
							<span className="shape1 position-absolute"><img src="assets/img/bg/dot-shape.png" alt=""/></span>
							<div className="inner-img">
								<img src="assets/img/about/furniture2.jpg" alt=""/>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="arck-about-text-wrap-2">
							<div className="arck-section-title headline pera-content">
								<span className="sub-title text-uppercase">About Archix</span>
								<h2>What We <span>Do.</span></h2>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting indust. Lorem Ipsum has been the dard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to a type speci  men book. It has survived not turies</p>
							</div>
							<div className="about-signature-img d-flex wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
								{/* <div className="inner-img">
									<img src="assets/img/about/abs1.jpg" alt=""/>
								</div>
								<div className="inner-text headline  position-relative">
									<h3>‘’Information architecture helps organize
										content overall so it’s easy to find and
									Imagine a storeroom filled use’’ </h3>
									<div className="about-author">
										<h4>Mrowni Rosen</h4>
										<span>CEO & Founder</span>
									</div>
									<span className="sign-img position-absolute"><img src="assets/img/about/sign.png" alt=""/></span>
								</div> */}
							</div>
							{/* <div className="arck-btn">
								<a className="d-flex justify-content-center align-items-center text-uppercase" href="project.html">View Works</a>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    {/* End of Who we do */}


    {/* Start of Who Choose Us */}
    <section id="about-page-about" className="about-page-about-section">
		<div className="container">
			<div className="about-page-about-content">
				<div className="row">
					<div className="col-lg-6">
						<div className="arck-about-img-wrapper position-relative">
							<div className="acrk-img-shape1 position-absolute"><i></i></div>
							<div className="acrk-img-shape2 position-absolute"><i></i></div>
							<span className="shape1 position-absolute"><img src="assets/img/bg/dot-shape.png" alt=""/></span>
							<div className="inner-img">
								<img src="assets/img/about/furniture3.jpg" alt=""/>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="arck-about-text-wrap-2">
							<div className="arck-section-title headline pera-content">
								<span className="sub-title text-uppercase">About Archix</span>
								<h2>Why Choose <span>Us</span></h2>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting indust. Lorem Ipsum has been the dard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to a type speci  men book. It has survived not turies</p>
							</div>
							<div className="about-signature-img d-flex wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
								{/* <div className="inner-img">
									<img src="assets/img/about/abs1.jpg" alt=""/>
								</div>
								<div className="inner-text headline  position-relative">
									<h3>‘’Information architecture helps organize
										content overall so it’s easy to find and
									Imagine a storeroom filled use’’ </h3>
									<div className="about-author">
										<h4>Mrowni Rosen</h4>
										<span>CEO & Founder</span>
									</div>
									<span className="sign-img position-absolute"><img src="assets/img/about/sign.png" alt=""/></span>
								</div> */}
							</div>
							{/* <div className="arck-btn">
								<a className="d-flex justify-content-center align-items-center text-uppercase" href="project.html">View Works</a>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    {/* End of Why choose us */}

	{/* Start of CTA section */}
	<section id="arck-cta" className="arck-cta-section position-relative" data-background="assets/img/bg/cta-bg.jpg">
		<div className="background_overlay"></div>
		<div className="container">
			<div className="arck-cta-content text-center headline position-relative">
				<h2>We Provide Affortable Services Save
				Time &  Save Mony. </h2>
				<div className="arck-cta-button-group d-flex justify-content-center">
					<div className="arck-btn">
						<a className="d-flex justify-content-center align-items-center text-uppercase" href="contact.html">Contact Us</a>
					</div>
					<div className="arck-cta-number d-flex align-items-center ">
						<i className="fal fa-phone-alt"></i>
						<a href="#">0318 148 965</a>
					</div>
				</div>
			</div>
		</div>
	</section>				
	{/* End of CTA section */}

	{/* Start of Counter section */}
	<section id="arck-counter" className="arck-counter-section">
		<div className="container">
			<div className="arck-counter-content">
				<div className="row">
					<div className="col-lg-3 col-md-6">
						<div className="arck-counter-inner-item headline pera-content text-center position-relative">
							<h3><span>30</span>k+</h3>
							<p>Happy Clients</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="arck-counter-inner-item headline pera-content text-center position-relative">
							<h3><span>34</span>k+</h3>
							<p>Completed Projects</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="arck-counter-inner-item headline pera-content text-center position-relative">
							<h3><span>212</span>+</h3>
							<p>Expert Engineer</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="arck-counter-inner-item headline pera-content text-center position-relative">
							<h3><span>100</span>+</h3>
							<p>National Awards </p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	{/* End of Counter section */}
    </>
  )
}

export default About
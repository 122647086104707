import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// import Swiper from 'swiper'
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/pagination";

// import "./styles.css";

function Home() {
    useEffect(() => {
		window.scrollTo(0,0)
	  },[])
      
    return (
        <>
            {/* Start of Slider section */}
            <section id="archx-slider" className="archx-slider-section position-relative" data-background="assets/img/slider-2/s2.jpeg">
                <span className="archx-slider-side1 position-absolute"><a href="/">Contact@gmail.com</a></span>
                <div className="archx-slider-side2 position-absolute ul-li">
                    <ul>
                        <li><a href="/">Twitter</a></li>
                        <li><a href="/">Facebook</a></li>
                        <li><a href="/">Instagram</a></li>
                    </ul>
                </div>
                <div className="container">
                    <div className="archx-slider-content position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="archx-slider-counter position-relative headline-2">
                                    <div className="archx-slider-counter-text text-center">
                                        <h2><span className="counter">29</span></h2>
                                        <p>Projects Completed This Year.</p>
                                    </div>
                                    <div className="ar-slider-shape-img position-absolute">
                                        <img src="assets/img/bg/ar-ba.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="archx-slider-text headline-2 pera-content">
                                    <div className="slider-slug  wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">Modular Furniture</div>
                                    <h1 className="wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">Manufacturer of Modular Furniture</h1>
                                    <p className=" wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">What's more, we do it right! A full modular furniture manufacturing background.</p>
                                    <div className="archx-video-play-btn d-flex align-items-center  wow fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
                                        <div className="archx-slider-btn">
                                            <Link className="d-flex justify-content-center align-items-center" to="/contact">Get quate now <i className="fal fa-long-arrow-right"></i></Link>
                                        </div>
                                        <div className="archx-video-play-btn">
                                            <a className="d-flex align-items-center video_box justify-content-center" href="https://www.youtube.com/watch?v=OB_Ok4dZ-dg"><i className="fas fa-play"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End of Slider section */}

            {/* Start of Produce section */}
            <section id="archx-feature" className="archx-feature-section position-relative">
                <span className="archx-bg position-absolute"><img src="assets/img/bg/ar-bg1.png" alt="" /></span>
                <div className="container">
                    <div className="archx-feature-content_2">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="archx-feature-img_2 position-relative  wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                                    <img src="assets/img/about/about1.jpeg" alt="" />
                                    <span className="archx-ft-shape position-absolute"><img src="assets/img/shape/ar-ft-sh.png" alt="" /></span>
                                    <div className="award-wining-text position-absolute">
                                        <span>20+</span> Award Winning Company
                                    </div>
                                    <div className="archx-experience-text text-center position-absolute pera-content">
                                        4<sub>+</sub>
                                        <p>decades of
                                            experince</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="archx-feature-content-item_2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="archx-feature-item_2 position-relative  wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                                <div className="archx-feature-icon position-relative">
                                                    <img src="assets/icon/ar-ic1.png" alt="" />
                                                </div>
                                                <div className="archx-feature-text headline-2 pera-content">
                                                    <h3>Reasonable Prices</h3>
                                                    <p>With so many players in modular furniture, why
                                                        opt for us – it’s simple, no one else looks at
                                                        furniture from your perspective the way we do</p>
                                                    <a href="service-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="archx-feature-item_2 position-relative wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                                <div className="archx-feature-icon position-relative">
                                                    <img src="assets/icon/ar-ic2.png" alt="" />
                                                </div>
                                                <div className="archx-feature-text headline-2 pera-content">
                                                    <h3>Exclusive Quality </h3>
                                                    <p>We would rather not take up the project than
                                                        compromise on quality. </p>
                                                    <a href="service-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="archx-feature-item_2 position-relative wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div className="archx-feature-icon position-relative">
                                                    <img src="assets/icon/ar-ic3.png" alt="" />
                                                </div>
                                                <div className="archx-feature-text headline-2 pera-content">
                                                    <h3>Modern Design</h3>
                                                    <p>Prove we have had our major orders from repeat
                                                        customers due to our consistent and timely
                                                        deliveries, every time.</p>
                                                    <a href="service-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="archx-feature-cta-text wow fadeInUp" data-wow-delay="900ms" data-wow-duration="1500ms">
                                        Ready To <a href="contact.html">Get Started?</a>  CALL +91 8983753843
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End of Produce section */}

            {/* Start of FAQ section */}
            <section id="archx-faq" className="archx-faq-section position-relative">
                <span className="ar-bg position-absolute"><img src="assets/img/bg/ar-bg2.png" alt="" /></span>
                <div className="container">
                    <div className="archx-faq-content">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="archx-section-title headline-2 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <span className="title-serial">01</span>
                                    <h2>Need To Know About Company Reading some answer & Sees <span> Question </span></h2>
                                </div>
                                <div className="archx-faq-content-wrapper">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item headline-2 pera-content wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    What are the types of modular furniture?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Modular Wardrobe Manufacturer.
                                                    Modular TV Wall Unit Manufacturer.
                                                    Modular Bed Sets Manufacturer.
                                                    Modular Chest of Drawers Manufacturer.
                                                    Modular Shoe Rack Cabinet Manufacturer.
                                                    Modular Book Storage Unit Manufacturer.
                                                    Modular Office Furniture Manufacturer.
                                                    Modular Workstations Manufacturer.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item headline-2 pera-content wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    What is modular furniture made of?

                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Image result for modular furniture asked questions and answers
                                                    What is Modular Furniture made of? Modular furniture is made from various modules of cabinets that further comprise the whole modular furniture. Unlike other furniture, modular furniture can be easily be designed as per the customer's needs, and the cabinets can be added or dismantled conveniently.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item headline-2 pera-content wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    How does modular furniture work?
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Image result for modular furniture asked questions and answers
                                                    Modular furniture is when separate pieces of furniture are used together to create a finished design in an office, home, or venue. This kind of design allows for unlimited possibilities for comfort, style, and safety because the pieces of furniture can be used in so many unique ways.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="archx-faq-img-wrap wow fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
                                    <img src="assets/img/about/1.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End of FAQ section */}

            {/* Start of Project section */}
            <section id="archx-project" className="archx-project-section">
                <div className="archx-project-content d-flex">
                    <div className="archx-project-item position-relative">
                        <div className="img-bg position-absolute">
                            <img src="assets/img/project/pro-bg.png" alt="" />
                        </div>
                        <div className="project-icon">
                            <img src="assets/icon/ar-pr2.png" alt="" />
                        </div>
                        <div className="project-text headline-2 pera-content">
                            <h3>Modular kitchen
                                Manufacturer.</h3>
                            <p>We build and activate brands through cultural insight
                                str vision, and the power of </p>
                            <a href="project-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                        </div>
                        <div className="hover-item">
                            <div className="inner-img">
                                <img src="assets/img/service/officeworkstation.jpeg" alt="" />
                            </div>
                            <div className="inner-text position-absolute">
                                <div className="inner-icon-serial d-flex align-items-center">
                                    <div className="inner-icon">
                                        <img src="assets/icon/ar-pr1.png" alt="" />
                                    </div>
                                    <div className="inner-serial">
                                        01
                                    </div>
                                </div>
                                <div className="inner-title headline-2">
                                    <h3>Modular kitchen
                                        Manufacturer.</h3>
                                    <a href="project-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="archx-project-item position-relative">
                        <div className="img-bg position-absolute">
                            <img src="assets/img/project/pro-bg.png" alt="" />
                        </div>
                        <div className="project-icon">
                            <img src="assets/icon/ar-pr2.png" alt="" />
                        </div>
                        <div className="project-text headline-2 pera-content">
                            <h3>Modular Bed Sets Manufacturer.</h3>
                            <p>We build and activate brands through cultural insight
                                str vision, and the power of </p>
                            <a href="project-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                        </div>
                        <div className="hover-item">
                            <div className="inner-img">
                                <img src="assets/img/service/wardrobe.jpeg" alt="" />
                            </div>
                            <div className="inner-text position-absolute">
                                <div className="inner-icon-serial d-flex align-items-center">
                                    <div className="inner-icon">
                                        <img src="assets/icon/ar-pr1.png" alt="" />
                                    </div>
                                    <div className="inner-serial">
                                        02
                                    </div>
                                </div>
                                <div className="inner-title headline-2">
                                    <h3>Modular Bed Sets Manufacturer.</h3>
                                    <a href="project-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="archx-project-item position-relative">
                        <div className="img-bg position-absolute">
                            <img src="assets/img/project/pro-bg.png" alt="" />
                        </div>
                        <div className="project-icon">
                            <img src="assets/icon/ar-pr2.png" alt="" />
                        </div>
                        <div className="project-text headline-2 pera-content">
                            <h3>Modular TV Wall Unit Manufacturer.</h3>
                            <p>We build and activate brands through cultural insight
                                str vision, and the power of </p>
                            <a href="project-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                        </div>
                        <div className="hover-item">
                            <div className="inner-img">
                                <img src="assets/img/service/residentalbedroom.jpeg" alt="" />
                            </div>
                            <div className="inner-text position-absolute">
                                <div className="inner-icon-serial d-flex align-items-center">
                                    <div className="inner-icon">
                                        <img src="assets/icon/ar-pr1.png" alt="" />
                                    </div>
                                    <div className="inner-serial">
                                        03
                                    </div>
                                </div>
                                <div className="inner-title headline-2">
                                    <h3>Modular TV Wall Unit Manufacturer.</h3>
                                    <a href="project-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="archx-project-item position-relative">
                        <div className="img-bg position-absolute">
                            <img src="assets/img/project/pro-bg.png" alt="" />
                        </div>
                        <div className="project-icon">
                            <img src="assets/icon/ar-pr2.png" alt="" />
                        </div>
                        <div className="project-text headline-2 pera-content">
                            <h3>Modular Workstations Manufacturer.</h3>
                            <p>We build and activate brands through cultural insight
                                str vision, and the power of </p>
                            <a href="project-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                        </div>
                        <div className="hover-item">
                            <div className="inner-img">
                                <img src="assets/img/service/residentalkitchen.jpeg" alt="" />
                            </div>
                            <div className="inner-text position-absolute">
                                <div className="inner-icon-serial d-flex align-items-center">
                                    <div className="inner-icon">
                                        <img src="assets/icon/ar-pr1.png" alt="" />
                                    </div>
                                    <div className="inner-serial">
                                        04
                                    </div>
                                </div>
                                <div className="inner-title headline-2">
                                    <h3>Modular Workstations Manufacturer.</h3>
                                    <a href="project-single.html">Know More <i className="fal fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Start of Project section */}

            {/* Start of Before After section */}
            {/* End of Before After section */}

            {/* Start of Service section */}
            <section id="archx-service" className="archx-service-section">
                <div className="container">
                    <div className="archx-service-content">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="archx-service-text-area">
                                    <div className="archx-section-title headline-2 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                        <span className="title-serial">04</span>
                                        <h2>We Think Working Process
                                            may <span>increase</span>  mindset
                                        </h2>
                                    </div>
                                    <div className="archx-service-list-wraper ul-li-block wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                        <ul>
                                            <li>More accurate match between press and proof</li>
                                            <li>Similar visual appearance across all print processes</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="archx-service-item-content">
                                    <div className="row">
                                        <div className="col-md-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                            <div className="archx-service-item">
                                                <span className="bg-img text-center position-absolute"><img src="assets/img/about/ar-sr1.png" alt="" /></span>
                                                <div className="service-serial">
                                                    01
                                                </div>
                                                <div className="service-title-text headline-2 pera-content">
                                                    <h3><a href="/">Visiting at home</a></h3>
                                                    <p>We build and activate br
                                                        through cultural insight</p>
                                                    <a className="more-btn" href="service-single.html"><i className="fal fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                            <div className="archx-service-item active">
                                                <span className="bg-img text-center position-absolute"><img src="assets/img/about/ar-sr1.png" alt="" /></span>
                                                <div className="service-serial">
                                                    02
                                                </div>
                                                <div className="service-title-text headline-2 pera-content">
                                                    <h3><a href="/">Visiting at home</a></h3>
                                                    <p>We build and activate br
                                                        through cultural insight</p>
                                                    <a className="more-btn" href="service-single.html"><i className="fal fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                            <div className="archx-service-item">
                                                <span className="bg-img text-center position-absolute"><img src="assets/img/about/ar-sr1.png" alt="" /></span>
                                                <div className="service-serial">
                                                    03
                                                </div>
                                                <div className="service-title-text headline-2 pera-content">
                                                    <h3><a href="/">Visiting at home</a></h3>
                                                    <p>We build and activate br
                                                        through cultural insight</p>
                                                    <a className="more-btn" href="service-single.html"><i className="fal fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End of Service section */}

            {/* Start of  About Section */}
             {/* <section id="archx-about" className="archx-about-section position-relative">
                <div className="container">
                    <div className="archx-about-content">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="archx-about-text-content">
                                    <div className="archx-section-title headline-2 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                        <h2>Making sure you have all the
                                            <span>services</span> you need your new project.
                                        </h2>
                                    </div>
                                    <div className="archx-about-sub-text headline-2 pera-content wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                        <h3>A Dedicated Master Degree Qualified Interior Designer</h3>
                                        <p>The other hand we denounce with righteou indg ation and dislike men who
                                            demorali ed by the of pleasure of the moment.Dislike men who are so </p>
                                    </div>
                                    <div className="archx-about-feature-list ul-li-block wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                        <ul>
                                            <li>Technical Lighting Structure / Plan Fixed & Free Standing</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7">

                                <div className="archx-about-sponsor-scroller">



                                    <div className="archx-about-sponsor-wrapper">
                                        <Swiper
                                            slidesPerView={3}
                                            spaceBetween={30}
                                            // pagination={{
                                            //   clickable: true,
                                            // }}
                                            modules={[Pagination]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide className="archx-sponsor-item" >
                                                <img src="https://images.pexels.com/photos/1866149/pexels-photo-1866149.jpeg?cs=srgb&dl=pexels-martin-p%C3%A9chy-1866149.jpg&fm=jpg" alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="archx-sponsor-item" >
                                                <img src="https://media.istockphoto.com/id/1293762741/photo/modern-living-room-interior-3d-render.jpg?b=1&s=170667a&w=0&k=20&c=Ko289acalSrEKwYI5sb20u2WQa7KS9L-_0Ug4u2iEmc=" alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="archx-sponsor-item" >
                                                <img src="https://media.istockphoto.com/id/968086564/photo/wooden-chairs-at-table-in-bright-open-space-interior-with-lamp-next-to-grey-couch-real-photo.jpg?b=1&s=612x612&w=0&k=20&c=Etyiq_zvSrlcBFZHCgtLvvaZ8vJbfI1wn8ueH9AMBik=" alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="archx-sponsor-item" >
                                                <img src="https://thumbs.dreamstime.com/b/vintage-furniture-flat-stylish-vintage-furniture-spacious-flat-interior-beige-sofa-chairs-posters-wall-112280434.jpg" alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="archx-sponsor-item" >
                                                <img src="https://www.shutterstock.com/image-photo/elegant-dark-apartment-interior-classic-260nw-1067812865.jpg" alt="" />
                                            </SwiperSlide>
                                            <SwiperSlide className="archx-sponsor-item" >
                                                <img src="https://res.duffieldtimber.com/content/best-types-of-timber-for-furniture-six-woods-you-should-choose-for-interior-diy-projects/_800x534_crop_center-center_none/Cabinets.jpg?mtime=20210601130035&focal=none" alt="" />
                                            </SwiperSlide>
                                             <div className="archx-sponsor-item">
                                            <img src="assets/img/sponsor/ar-sp2.png" alt="" />
                                        </div>
                                        <div className="archx-sponsor-item">
                                            <img src="assets/img/sponsor/ar-sp1.png" alt="" />
                                        </div>
                                        <div className="archx-sponsor-item">
                                            <img src="assets/img/sponsor/ar-sp2.png" alt="" />
                                        </div>
                                        <div className="archx-sponsor-item">
                                            <img src="assets/img/sponsor/ar-sp1.png" alt="" />
                                        </div>
                                        <div className="archx-sponsor-item">
                                            <img src="assets/img/sponsor/ar-sp2.png" alt="" />
                                        </div> 
                                    </Swiper>
                                    </div>


                                    <div className="archx-about-experience-scoller d-flex">
                                        <div className="archx-about-experience text-center headline-2 pera-content d-flex align-items-center justify-content-center wow fadeLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                                            <div className="archx-about-exp-text">
                                                <h3>
                                                    25<sub>+</sub>
                                                </h3>
                                                <p>Include Service
                                                    Features</p>
                                            </div>
                                        </div>
                                        <div className="archx-about-scroller-wrapper wow fadeInRight" data-wow-delay="400ms" data-wow-duration="1500ms">
                                            <div className="archx-about-scroller-content">
                                                <div className="archx-about-scroller-item headline-2 pera-content">
                                                    <h3>A Dedicated Master Degree Qualified Interior Designer</h3>
                                                    <p>The other hand we denounce with righteou indg ation and dislike men who
                                                        demorali ed by the of pleasure of the moment.Dislike men who are so </p>
                                                </div>
                                                <div className="archx-about-scroller-item headline-2 pera-content">
                                                    <h3>A Dedicated Master Degree Qualified Interior Designer</h3>
                                                    <p>The other hand we denounce with righteou indg ation and dislike men who
                                                        demorali ed by the of pleasure of the moment.Dislike men who are so </p>
                                                </div>
                                                <div className="archx-about-scroller-item headline-2 pera-content">
                                                    <h3>A Dedicated Master Degree Qualified Interior Designer</h3>
                                                    <p>The other hand we denounce with righteou indg ation and dislike men who
                                                        demorali ed by the of pleasure of the moment.Dislike men who are so </p>
                                                </div>
                                                <div className="archx-about-scroller-item headline-2 pera-content">
                                                    <h3>A Dedicated Master Degree Qualified Interior Designer</h3>
                                                    <p>The other hand we denounce with righteou indg ation and dislike men who
                                                        demorali ed by the of pleasure of the moment.Dislike men who are so </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  */}
            {/* End of  About Section */}

            {/* Start of  Contact Section */}
            <section id="archx-contact" className="archx-contact-section">
                <div className="container">
                    <div className="archx-contact-content">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="archx-contact-img-counter">
                                    <div className="archx-contact-img position-relative wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                        <img src="assets/img/about/2.jpg" alt="" />
                                        <span className="img-shape"></span>
                                    </div>
                                    {/* <div className="archx-counter-wrapper d-flex justify-content-between">
                                        <div className="archx-counter-item headline-2 pera-content">
                                            <h3><span className="counter">3.3</span>M</h3>
                                            <p>Square Feet Built</p>
                                        </div>
                                        <div className="archx-counter-item headline-2 pera-content">
                                            <h3><span className="counter">98</span></h3>
                                            <p>Awards Received</p>
                                        </div>
                                        <div className="archx-counter-item headline-2 pera-content">
                                            <h3><span className="counter">104</span></h3>
                                            <p>Worlds Clients</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="archx-contact-form-wrapper">
                                    <div className="archx-section-title headline-2 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                        <h2>
                                            Get In  <span>Touch</span> With Us
                                        </h2>
                                    </div>
                                    <div className="archx-contact-form wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                        <form action="#" method="get">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type="text" name="name" placeholder="Enter your name*" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" name="name" placeholder="Enter your name*" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" name="name" placeholder="Enter your name*" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" name="name" placeholder="Enter your name*" />
                                                </div>
                                                <div className="col-md-12">
                                                    <textarea name="message" placeholder="Enter Your Message*"></textarea>
                                                </div>
                                                <div className="col-md-12">
                                                    <button type="submit">Send us Messege <i className="fal fa-long-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End of  Contact Section */}

            {/* Start of  Project Section */}
            {/* <section id="archx-project-2" className="archx-project-section-2" data-background="assets/img/bg/ar-pro-bg.png">
                <div className="container">
                    <div className="archx-project-top-content d-flex justify-content-between align-items-center">
                        <div className="archx-section-title headline-2">
                            <h2>See <span> Projects </span> History Of honourable World
                            </h2>
                        </div>
                        <div className="archx-section-title headline-2">
                            <span className="title-serial">06</span>
                        </div>
                        <div className="carousel_nav">
                            <button type="button" className="ar-pro_left_arrow text-uppercase"><i className="fal fa-long-arrow-left"></i></button>
                            <button type="button" className="ar-pro_right_arrow text-uppercase"><i className="fal fa-long-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
                <div className="archx-project-slider-area">
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                      
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <div className="archx-project-slider " >


                            <SwiperSlide className="archx-project-item-2 swiper-slide " >
                                <div className="archx-project-item-content  position-relative">

                                    <div className="archx-project-img-2">
                                        <img src="assets/img/project/ar-pro1.png" alt="" />
                                    </div>
                                    <div className="serial-no d-flex justify-content-center align-items-center position-absolute">
                                    01
                                </div>
                                    <div className="archx-project-text-2 d-flex justify-content-between headline-2 position-absolute align-items-center">
                                        <h3><a href="project-single.html">Book Printing Service</a></h3>
                                        <a className="more_btn d-flex align-items-center justify-content-center" href="project-single.html"> <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="archx-project-item-2">
                                <div className="archx-project-item-content  position-relative">
                                    <div className="archx-project-img-2">
                                        <img src="assets/img/project/ar-pro2.png" alt="" />
                                    </div>
                                     <div className="serial-no d-flex justify-content-center align-items-center position-absolute">
                                    02
                                </div>
                                    <div className="archx-project-text-2 d-flex justify-content-between headline-2 position-absolute align-items-center">
                                        <h3><a href="project-single.html">Book Printing Service</a></h3>
                                        <a className="more_btn d-flex align-items-center justify-content-center" href="project-single.html"> <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="archx-project-item-2">
                                <div className="archx-project-item-content  position-relative">
                                    <div className="archx-project-img-2">
                                        <img src="assets/img/project/ar-pro3.png" alt="" />
                                    </div>
                                    <div className="serial-no d-flex justify-content-center align-items-center position-absolute">
                                    03
                                </div>
                                    <div className="archx-project-text-2 d-flex justify-content-between headline-2 position-absolute align-items-center">
                                        <h3><a href="project-single.html">Book Printing Service</a></h3>
                                        <a className="more_btn d-flex align-items-center justify-content-center" href="project-single.html"> <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="archx-project-item-2">
                                <div className="archx-project-item-content  position-relative">
                                    <div className="archx-project-img-2">
                                        <img src="assets/img/project/ar-pro4.png" alt="" />
                                    </div>
                                    <div className="serial-no d-flex justify-content-center align-items-center position-absolute">
                                    04
                                </div>
                                    <div className="archx-project-text-2 d-flex justify-content-between headline-2 position-absolute align-items-center">
                                        <h3><a href="project-single.html">Book Printing Service</a></h3>
                                        <a className="more_btn d-flex align-items-center justify-content-center" href="project-single.html"> <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="archx-project-item-2">
                                <div className="archx-project-item-content  position-relative">
                                    <div className="archx-project-img-2">
                                        <img src="assets/img/project/ar-pro5.png" alt="" />
                                    </div>
                                    <div className="serial-no d-flex justify-content-center align-items-center position-absolute">
                                    05
                                </div>
                                    <div className="archx-project-text-2 d-flex justify-content-between headline-2 position-absolute align-items-center">
                                        <h3><a href="project-single.html">Book Printing Service</a></h3>
                                        <a className="more_btn d-flex align-items-center justify-content-center" href="project-single.html"> <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="archx-project-item-2">
                                <div className="archx-project-item-content  position-relative">
                                    <div className="archx-project-img-2">
                                        <img src="assets/img/project/ar-pro1.png" alt="" />
                                    </div>
                                     <div className="serial-no d-flex justify-content-center align-items-center position-absolute">
                                    06
                                </div> 
                                    <div className="archx-project-text-2 d-flex justify-content-between headline-2 position-absolute align-items-center">
                                        <h3><a href="project-single.html">Book Printing Service</a></h3>
                                        <a className="more_btn d-flex align-items-center justify-content-center" href="project-single.html"> <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="archx-project-item-2">
                                <div className="archx-project-item-content  position-relative">
                                    <div className="archx-project-img-2">
                                        <img src="assets/img/project/ar-pro2.png" alt="" />
                                    </div>
                                    <div className="serial-no d-flex justify-content-center align-items-center position-absolute">
                                    07
                                </div>
                                    <div className="archx-project-text-2 d-flex justify-content-between headline-2 position-absolute align-items-center">
                                        <h3><a href="project-single.html">Book Printing Service</a></h3>
                                        <a className="more_btn d-flex align-items-center justify-content-center" href="project-single.html"> <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </div>
                    </Swiper>
                </div> 
                <div className="archx-newslatter-wrapper">
                    <div className="container">
                        <div className="archx-newslatter-content d-flex justify-content-between">
                            <div className="archx-newslatter-cta d-flex align-items-center">
                                <div className="inner-icon">
                                    <img src="assets/icon/ar-nw.png" alt="" />
                                </div>
                                <div className="inner-text">
                                    <span className="cta-no">+44(0) 203 808 51</span>
                                    <span className="cta-text">Ready To <a href="/">Get Started?</a> give a call </span>
                                </div>
                            </div>
                            <div className="archx-newslatter-form">
                                <div className="newslatter-form position-relative">
                                    <span className="bg-icon position-absolute"><i className="fas fa-envelope"></i></span>
                                    <form action="#" method="get">
                                        <input type="email" name="email" placeholder="Enter your Email" />
                                        <button type="submit">Subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  */}
            {/* End of  Project Section */}


            {/* Start of Blog Section */}
            {/* <section id="archx-blog" className="archx-blog-section">
                <div className="container">
                    <div className="archx-section-title text-center headline-2 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <h2>Please Read & know about our blogs leatest <span> news </span>
                        </h2>
                    </div>
                    <div className="archx-blog-content">
                        <div className="row">
                            <div className="col-lg-4 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div className="archx-blog-item">
                                    <div className="blog-img position-relative">
                                        <img src="assets/img/blog/ar-blg1.png" alt="" />
                                        <span className="blog-category d-flex justify-content-center align-items-center text-uppercase position-absolute"><a href="/">Story</a></span>
                                    </div>
                                    <div className="blog-text">
                                        <div className="blog-meta ul-li">
                                            <ul>
                                                <li><a href="/"><i className="fal fa-calendar-alt"></i> 02 Apr 2021</a></li>
                                                <li><a href="/"><i className="fal fa-comment"></i> Comments (03)</a></li>
                                            </ul>
                                        </div>
                                        <div className="blog-title headline-2">
                                            <h3><a href="blog-single.html">Building the architecture of the
                                                future brand new cities</a></h3>
                                        </div>
                                        <div className="blog-read-more">
                                            <a href="blog-single.html">Read More <i className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                                <div className="archx-blog-item">
                                    <div className="blog-img position-relative">
                                        <img src="assets/img/blog/ar-blg2.png" alt="" />
                                        <span className="blog-category d-flex justify-content-center align-items-center text-uppercase position-absolute"><a href="/">Story</a></span>
                                    </div>
                                    <div className="blog-text">
                                        <div className="blog-meta ul-li">
                                            <ul>
                                                <li><a href="/"><i className="fal fa-calendar-alt"></i> 02 Apr 2021</a></li>
                                                <li><a href="/"><i className="fal fa-comment"></i> Comments (03)</a></li>
                                            </ul>
                                        </div>
                                        <div className="blog-title headline-2">
                                            <h3><a href="blog-single.html">Building the architecture of the
                                                future brand new cities</a></h3>
                                        </div>
                                        <div className="blog-read-more">
                                            <a href="blog-single.html">Read More <i className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div className="archx-blog-item">
                                    <div className="blog-img position-relative">
                                        <img src="assets/img/blog/ar-blg3.png" alt="" />
                                        <span className="blog-category d-flex justify-content-center align-items-center text-uppercase position-absolute"><a href="/">Story</a></span>
                                    </div>
                                    <div className="blog-text">
                                        <div className="blog-meta ul-li">
                                            <ul>
                                                <li><a href="/"><i className="fal fa-calendar-alt"></i> 02 Apr 2021</a></li>
                                                <li><a href="/"><i className="fal fa-comment"></i> Comments (03)</a></li>
                                            </ul>
                                        </div>
                                        <div className="blog-title headline-2">
                                            <h3><a href="blog-single.html">Building the architecture of the
                                                future brand new cities</a></h3>
                                        </div>
                                        <div className="blog-read-more">
                                            <a href="blog-single.html">Read More <i className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* End of Blog Section */}


            {/* Start of Map Section */}
            <section id="archx-map" className="archx-map-section position-relative">
                <div className="archx-map-wrap">
                    <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.5659676752307!2d72.84592665!3d19.3446315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ae4ddbe5e035%3A0x99d095f46bf31ecd!2sDias%20%26%20Pereira%20Nagar%2C%20Naigaon%20West%2C%20Pali%2C%20Maharashtra%20401202!5e0!3m2!1sen!2sin!4v1677669541264!5m2!1sen!2sin" width="100%" height="470"></iframe>
                </div>
                <div className="container">
                    <div className="archx-map-info">
                        <div className="archx-map-contact-info headline-2 pera-content">
                            <div className="archx-map-info-item">
                                <div className="info-title d-flex align-items-center">
                                    <span><i className="fas fa-address-book"></i></span>
                                    <h3>Our Address</h3>
                                </div>
                                <div className="info-text">
                                    E 3/2 , Pricilla Apartments, Dias and Pereira Nagar, Vijay Park, Naigaon - West, Palghar district
                                    401207- Maharashtra, India.
                                </div>
                            </div>
                            <div className="archx-map-info-item">
                                <div className="info-title d-flex align-items-center">
                                    <span><i className="fas fa-address-book"></i></span>
                                    <h3>Contact Way</h3>
                                </div>
                                <div className="info-text ul-li-block">
                                    <ul>
                                        <li><i className="fas fa-comments"></i> wudpulse28@gmail.com</li>
                                        <li><i className="fas fa-phone-alt"></i> (+91) 98201 56161</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End of Map Section */}


            {/* Start of Social Section */}
            {/* <section id="archx-social" className="archx-social-section">
                <div className="archx-social-content d-flex align-items-center">
                    <div className="archx-social-item">
                        <a href="/">
                            <i className="fab fa-facebook-f"></i>
                            <span>Facebook</span>
                        </a>
                    </div>
                    <div className="archx-social-item">
                        <a href="/">
                            <i className="fab fa-linkedin"></i>
                            <span>Linkdin</span>
                        </a>
                    </div>
                    <div className="archx-social-item">
                        <a href="/">
                            <i className="fab fa-twitter"></i>
                            <span>Twitter</span>
                        </a>
                    </div>
                    <div className="archx-social-item">
                        <a href="/">
                            <i className="fab fa-instagram"></i>
                            <span>Instagram</span>
                        </a>
                    </div>
                    <div className="archx-social-item">
                        <a href="/">
                            <i className="fab fa-youtube"></i>
                            <span>Youtube</span>
                        </a>
                    </div>
                    <div className="archx-social-item">
                        <a href="/">
                            <i className="fab fa-pinterest"></i>
                            <span>Pinterest</span>
                        </a>
                    </div>
                </div>
            </section> */}
            {/* End of Social Section */}

        </>
    )
}

export default Home
import React from 'react'

function Services() {
    return (
        <>
            {/* Start of Breadcrumb section */}
            <section id="arck-breadcrumb" className="arck-breadcrumb-section-2 position-relative" data-background="assets/img/bg/ar-shape.png">
                <div className="slider-side-content position-absolute">
                    <span className="archx-slider-side1 position-absolute"><a href="#">Contact@gmail.com</a></span>
                </div>
                <div className="container">
                    <div className="arck-breadcrumb-content position-relative text-center headline-2 ul-li">
                        <h1>Services</h1>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>Services</li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* End of Breadcrumb section */}

            {/* Start of Project Page content section */}
            <section id="arck-project-feed" className="arck-project-feed-section inner-page-padding">
                <div className="arck-project-filter-content">
                    <div className="filtr-container-area grid clearfix" data-isotope="{ &quot;masonry&quot;: { &quot;columnWidth&quot;: 0 } }">
                        <div className="grid-sizer"></div>

                        <div className="grid-item grid-size-50 interiors design" data-category="interiors design">
                            <div className="arck-project-item position-relative two">
                                <div className="inner-img">
                                    <img src="assets/img/service/officeworkstation.jpeg" alt="" style={{width :"800", height:"auto" ,objectFit:"contain"}} />
                                </div>
                                <div className="inner-text">
                                    <div className="project-title-desc headline pera-content">
                                        {/* <span className="text-uppercase item-category"><a href="#">Architecture</a></span> */}
                                        <h3><a href="project-single.html">Modular kitchen Manufacturer.</a></h3>
                                        <p>We build and activate brands through cultural insight str vision, and the power of </p>
                                    </div>
                                </div>
                                <div className="view-more-btn text-uppercase position-absolute">
                                    <a href="project-single.html">View Details <i className="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item grid-size-50 building design" data-category="building design">
                            <div className="arck-project-item position-relative two">
                                <div className="inner-img">
                                    <img src="assets/img/service/wardrobe1.jpeg" alt="" style={{width :"800", height:"auto" ,objectFit:"contain"}} />
                                </div>
                                <div className="inner-text">
                                    <div className="project-title-desc headline pera-content">
                                        {/* <span className="text-uppercase item-category"><a href="#">Modular kitchen Manufacturer.</a></span> */}
                                        <h3><a href="project-single.html">Modular TV Wall Unit Manufacturer.</a></h3>
                                        <p>We build and activate brands through cultural insight str vision, and the power of</p>
                                    </div>
                                </div>
                                <div className="view-more-btn text-uppercase position-absolute">
                                    <a href="project-single.html">View Details <i className="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>


                        <div className="grid-item grid-size-50 interiors design" data-category="interiors design">
                            <div className="arck-project-item position-relative two">
                                <div className="inner-img">
                                    <img src="assets/img/service/residentalbedroom.jpeg" alt="" style={{width :"800", height:"auto" ,objectFit:"contain"}} />
                                </div>
                                <div className="inner-text">
                                    <div className="project-title-desc headline pera-content">
                                        {/* <span className="text-uppercase item-category"><a href="#">Architecture</a></span> */}
                                        <h3><a href="project-single.html">Modular Workstations Manufacturer.</a></h3>
                                        <p>We build and activate brands through cultural insight str vision, and the power of </p>
                                    </div>
                                </div>
                                <div className="view-more-btn text-uppercase position-absolute">
                                    <a href="project-single.html">View Details <i className="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item grid-size-50 interiors design" data-category="interiors design">
                            <div className="arck-project-item position-relative two">
                                <div className="inner-img">
                                    <img src="assets/img/service/residentalkitchen.jpeg" alt=""  style={{width :"200", height:"auto" ,objectFit:"contain"}}/>
                                </div>
                                <div className="inner-text">
                                    <div className="project-title-desc headline pera-content">
                                        {/* <span className="text-uppercase item-category"><a href="#">Architecture</a></span> */}
                                        <h3><a href="project-single.html">Modular kitchen Manufacturer.</a></h3>
                                        <p>We build and activate brands through cultural insight str vision, and the power of </p>
                                    </div>
                                </div>
                                <div className="view-more-btn text-uppercase position-absolute">
                                    <a href="project-single.html">View Details <i className="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
            {/* End of Project Page content section */}
        </>
    )
}

export default Services
import React, { useEffect } from 'react'

function Contact() {

  useEffect(() => {
		window.scrollTo(0,0)
	  },[])

  return (
    <>

      {/* Start of Breadcrumb section	 */}
      <section id="arck-breadcrumb" className="arck-breadcrumb-section-2 position-relative" data-background="assets/img/bg/ar-shape.png">
        <div className="slider-side-content position-absolute">
          <span className="archx-slider-side1 position-absolute"><a href="#">Contact@gmail.com</a></span>
        </div>
        <div className="container">
          <div className="arck-breadcrumb-content position-relative text-center headline-2 ul-li">
            <h1>Contact</h1>
            <ul>
              <li><a href="#">Home</a></li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </section>
      {/* End of Breadcrumb section */}


      {/* Start of Contact page content section */}
      <section id="arck-contact-page" className="arck-contact-page-section inner-page-padding">
        <div className="container">
          <div className="arck-contact-page-content">
            <div className="row">
              <div className="col-lg-6">
                <div className="arck-contact-page-cta " >
                  {/* <div className="contact-cta-inner-img position-relative">
                    <img src="assets/img/about/cn-bg.jpg" alt="" />
                  </div> */}
                  <div className="contact-cta-inner-text  headline pera-content text-light " style={{
                     backgroundImage: "url(" + "assets/img/about/cn-bg.jpg" + ")",
                  }}  >
                    <h3 style={{textAlign:"center"}}>Contact Details</h3>
                    <div className="arck-video-cta-wrap">
                      <div className="video-cta-item d-flex">
                        <div className="inner-icon">
                          <img src="assets/icon/ic8.png" alt="" />
                        </div>
                        <div className="inner-text headline pera-content">
                          <h3>Office Address:</h3>
                          <p> E 3/2 , Pricilla Apartments, Dias and Pereira Nagar, Vijay Park, Naigaon - West,</p>
                          <p>Palghar district 401207- Maharashtra, India.</p>
                        </div>
                      </div>
                      <div className="video-cta-item d-flex">
                        <div className="inner-icon">
                          <img src="assets/icon/ic9.png" alt="" />
                        </div>
                        <div className="inner-text headline pera-content">
                          <h3>Mail Us:</h3>
                          <p>wudpulse28@gmail.com
                          </p>
                         
                        </div>
                      </div>
                      <div className="video-cta-item d-flex">
                        <div className="inner-icon">
                          <img src="assets/icon/ic10.png" alt="" />
                        </div>
                        <div className="inner-text headline pera-content">
                          <h3>Telephone:</h3>
                         
                          <p>(+91) 98201 56161</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="arck-contact-page-form">
                  <div className="arck-appointment-form-wrap">
                    <div className="arck-section-title headline pera-content">
                      <span className="sub-title text-uppercase">Get A Quote</span>
                      <h2>Keep Get in Touch</h2>
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua
                      </p> */}
                    </div>
                    <form action="#" method="get">
                      <div className="row">
                        <div className="col-md-6">
                          <input type="text" name="name" placeholder="First Name" />
                        </div>
                        <div className="col-md-6">
                          <input type="text" name="name" placeholder="Last Name" />
                        </div>
                        <div className="col-md-6">
                          <input type="text" name="phone" placeholder="Phone Number" />
                        </div>
                        <div className="col-md-6">
                          <input type="email" name="email" placeholder="E-mail" />
                        </div>
                        <div className="col-md-12">
                          <textarea name="message" placeholder="Massaege"></textarea>
                        </div>
                      </div>
                      <button type="submit">Submit Now</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Contact page content  */}

      {/* Start Google Map */}
      <section id="archx-map" className="archx-map-section position-relative">
        <div className="archx-map-wrap">
          <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.5659676752307!2d72.84592665!3d19.3446315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ae4ddbe5e035%3A0x99d095f46bf31ecd!2sDias%20%26%20Pereira%20Nagar%2C%20Naigaon%20West%2C%20Pali%2C%20Maharashtra%20401202!5e0!3m2!1sen!2sin!4v1677669541264!5m2!1sen!2sin" width="100%" height="470"></iframe>
        </div>
      </section>

      {/* End Google Map */}
    </>
  )
}

export default Contact